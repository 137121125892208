import { Component, Input } from '@angular/core';
import { PlatformName } from '@shared/models/user';
import { rsSkinPrefix } from '@lib/rs-ngx';

@Component({
  selector: 'mgt-platform-chip',
  templateUrl: './platform-chip.component.html',
  styleUrls: ['./platform-chip.component.scss'],
  standalone: false
})
export class PlatformChipComponent {

  @Input() public platforms!: {
    name: PlatformName;
    isManager?: boolean
  }[];

  public readonly rsSkinPrefix = rsSkinPrefix;

  constructor() {

  }
}
