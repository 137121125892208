import { Component, effect, input, Input, OnInit } from '@angular/core';
import { TranslationService, TranslationsLanguage } from '@lib/rs-ngx';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfileForm } from '@shared/components/user-profile-form/models/user-profile-form';
import { AccountWithManager } from '@review-company-manager/models/account-with-manager';
import { impersonalEmailValidator } from '../../validators/impersonalEmailValidator';

@Component({
  selector: 'mgt-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
  standalone: false
})
export class UserProfileFormComponent implements OnInit {

  public readonly userProfileForm: FormGroup<UserProfileForm>;
  public readonly languages: TranslationsLanguage[];

  protected readonly filterImpersonalEmails = input(false);
  protected readonly isEmailDisabled = input(true);
  protected readonly initialValue = input<AccountWithManager | null>(null);

  constructor(
        private readonly translationService: TranslationService
  ) {
    this.languages = this.translationService.languages;
    this.userProfileForm = new FormGroup<UserProfileForm>({
      email: new FormControl({
        value: '',
        disabled: this.isEmailDisabled()
      }, {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.email
        ],
      }),
      firstName: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.maxLength(29)
        ]
      }),
      lastName: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.maxLength(30)
        ]
      }),
      language: new FormControl(null, [Validators.required]),
      jobTitle: new FormControl('', { nonNullable: true }),
      phone: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.maxLength(20),
          Validators.pattern(/^[+{0}\d(][\s0-9()/]+[0-9]$/),
        ]
      }),
      mobile: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.maxLength(20),
          Validators.pattern(/^[+{0}\d(][\s0-9()/]+[0-9]$/)
        ]
      })
    });

    effect(() => {
      this.filterImpersonalEmails() ? this.addImpersonalEmailValidator() : this.removeImpersonalEmailValidator();
    });
  }

    @Input() public set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.userProfileForm.disable();
    } else {
      this.userProfileForm.enable();
    }
  }

    public ngOnInit(): void {
      if (this.isEmailDisabled()) {
        this.userProfileForm.controls.email.disable();
      } else {
        this.userProfileForm.controls.email.enable();
      }

      if (this.initialValue()) {
        this.userProfileForm.patchValue(this.initialValue()!);
      }
    }

    private addImpersonalEmailValidator(): void {
      this.userProfileForm.controls.email.addValidators(impersonalEmailValidator());
      this.userProfileForm.controls.email.updateValueAndValidity();
    }

    private removeImpersonalEmailValidator(): void {
      this.userProfileForm.controls.email.removeValidators(impersonalEmailValidator());
      this.userProfileForm.controls.email.updateValueAndValidity();
    }
}
